<template>
  <v-navigation-drawer right class="navigation-drawer" :value="dialog" width="400px" app>
    <template v-slot:prepend>
      <v-toolbar>
        <v-toolbar-title>Reporte</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
      </v-toolbar>
    </template>
    <v-card>
      <v-card-text class="pa-2 text-center">
        <!-- <iframe
          :src="invoiceImg"
          width="100%"
          height="80vh"
          class="cart-list"
          frameborder="0"
        >
        </iframe> -->

        <v-img :src="invoiceImg"></v-img>

        <v-img :src="urlCufe" id="CufeQR" width="120px"></v-img>
        <!-- <v-btn
          v-if="item.document.cufe"
          color="success"
          x-large
          @click="send_invoice(item.document.cufe)"
        >
          Enviar Correo
        </v-btn> -->
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <v-toolbar class="fixed-footer">
        <v-speed-dial
          v-model="fab"
          direction="top"
          open-on-clic
          transition="slide-y-reverse-transition"
          class="pl-3"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="success" dark> DIAN </v-btn>
          </template>

          <v-btn
            color="success"
            @click="edit_nc()"
            class="ml-12"
            v-if="
              item.document.doc_type === 'invoice' &&
              item.document.cufe &&
              item.document.doc_reference === null
            "
          >
            Nota Crédito
          </v-btn>

          <v-btn
            color="success"
            @click="e_invoice_fe()"
            v-if="item.document.doc_type === 'invoice'"
            class="ml-12"
          >
            Reenviar Dian
          </v-btn>
          <v-btn
            color="success"
            @click="e_credit_note(item)"
            v-if="item.document.doc_type === 'NC'"
          >
            Enviar NC
          </v-btn>
        </v-speed-dial>

        <!-- <v-btn color="success" @click="post_status_zip(item.document.zip)" v-if="item.document.doc_type==='invoice'"> ZIP Status </v-btn> -->

        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="send_invoice(item.document.cufe)"
          v-if="
            item.document.doc_type === 'invoice' &&
            item.buyer.PartyEmail &&
            item.document.cufe
          "
        >
          Enviar Correo
        </v-btn>
        <!-- <v-btn color="success" @click="get_e_doc_credit_note()"> Anular </v-btn> -->

        <v-spacer></v-spacer>
        <v-btn color="info" @click="print_doc()"> Imprimir </v-btn>
      </v-toolbar>
    </template>

    <v-dialog
      v-model="dian_dialog"
      transition="dialog-fade-transition"
      scrollable
      max-width="100%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="success">
            DIAN
            <v-spacer></v-spacer>
            <v-icon @click="dian_dialog = !dian_dialog"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                readonly
                v-model="dian_response.status_description"
                label="Descripción"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                readonly
                v-model="dian_response.status_message"
                label="Descripción"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                readonly
                v-model="dian_response.issue_date"
                label="Fecha"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="12" v-if="dian_response.is_valid">
              <iframe
                :src="dian_response.qr_link"
                width="100%"
                height="80vh"
                frameborder="0"
                class="cart-list"
              >
              </iframe>
            </v-col>
          </v-row>
          <v-btn
            light
            color="success"
            x-large
            :href="dian_response.pdf_download_link"
            target="_blank"
          >
            Descargar PDF
          </v-btn>
        </v-card-text>
        <v-card-text class="pt-4" v-if="dian_response.message">
          <v-alert outlined type="error">
            {{ dian_response.payload }}
          </v-alert>
          <!-- <v-textarea label="Errors" v-model="dian_response.errors"></v-textarea> -->
          <pre>{{ JSON.stringify(dian_response.errors, null, 2) }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading_status" hide-overlay persistent>
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          <p>Respuesta</p>
          <p>Conectando con DIAN.</p>
          <v-progress-linear indeterminate color="green" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_erros"
      dark
      persistent
      width="50%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card color="primary">
        <v-card-title> Alerta </v-card-title>
        <v-card-text>
          <pre>{{ JSON.stringify(dian_response.errors, null, 2) }}</pre>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer
          ><v-btn @click="dialog_erros = !dialog_erros"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_mail"
      dark
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card color="green">
        <v-card-title> {{ mail_response.status_description }} </v-card-title>
        <v-card-subtitle> {{ mail_response.status_message }} </v-card-subtitle>
        <v-card-text>
          <pre>{{ JSON.stringify(mail_response.mail_sending_message, null, 2) }}</pre>
          <pre>{{ JSON.stringify(mail_response.errors, null, 2) }}</pre>
          <!-- <iframe
                :src="mail_response.qr_link"
                width="100%"
                height="80vh"
                frameborder="0"
                class="cart-list"
              >
              </iframe> -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer><v-btn @click="dialog_mail = !dialog_mail"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="nc_dialog"
      transition="dialog-fade-transition"
      scrollable
      max-width="650"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="mx-auto">
        <v-card-title class="pa-0">
          <v-toolbar dark color="pink darken-3">
            Nota Crédito - {{ item.document.doc_number }}
            <v-spacer></v-spacer>
            <v-icon @click="nc_dialog = !nc_dialog"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 pt-4">
          <v-data-table
            v-model="selected"
            :headers="headers_nc"
            :items="nc_items"
            :items-per-page="-1"
            class="elevation-1"
            calculate-widths
            mobile-breakpoint="0"
            item-key="item_code"
            :loading="loading_status"
            loader-height="10"
            loading-text="Cargando ..."
            hide-default-footer
            show-select
          >
            <template v-slot:[`item.cantidad`]="{ item }">
              <v-btn fab icon @click="editItem(item)">
                {{ item.cantidad }}
              </v-btn>
            </template>
            <template v-slot:[`item.refe`]="{ item }">
              {{
                item.categoria +
                "-" +
                item.marca +
                "-" +
                item.refe +
                "-" +
                item.color +
                "-" +
                item.talla
              }}
            </template>
            <template v-slot:[`item.precio`]="{ item }">
              {{ "$ " + (item.precio * item.cantidad).toLocaleString(2) }}
              <br />
              <small
                class="red--text text-decoration-line-through"
                v-if="item.discount > 0"
                >{{
                  "$ " + ((item.discount + item.precio) * item.cantidad).toLocaleString(2)
                }}
              </small>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn @click="create_nc" v-if="selected.length > 0">
              Crear Nota Crédito
            </v-btn>
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import { fe_ws } from "../services/webserver.js";
import createDoc from "../utils/create_doc.js";
import dianResponse from "../utils/dianResponse.js";
import settings from "../utils/settings.js";
import { webserver } from "../services/webserver.js";
import createCompany from "../utils/createCompany.js";

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: createDoc(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newInvoice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    // console.log("=======>", this.settings["type_liabilities"]);
    this.get_company();
  },
  data() {
    return {
      fab: false,
      nc_dialog: false,
      nc_items: [],
      headers_nc: [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "item_concept",
          dataType: "text",
        },
        {
          text: "Qty",
          align: "center",
          sortable: true,
          value: "item_quantity",
          dataType: "text",
          width: "10",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "item_price",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      selected: [],
      invoiceImg: null,
      urlCufe: null,
      dian_dialog: false,
      dialog_mail: false,
      dian_response: dianResponse(),
      mail_response: dianResponse(),
      docview: "",
      loading_status: false,
      company: createCompany(),
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      settings: settings(),
      dialog_erros: false,
    };
  },
  methods: {
    edit_nc() {
      console.log("edit_nc", this.item);
      this.nc_items = this.item.items;
      this.nc_dialog = true;
    },
    get_company() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "store_business_id",
            operator: "=",
            value: window.store.store_business_id,
          },
        ],
      };
      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        if (data.length > 0) {
          this.company = data[0];
        }
      });
    },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%"/><br>');
      a.document.write('<img src="' + this.urlCufe + '"  width="200px"/>');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },

    print_invoice(data) {
      console.log(data);

      var LS = 50;
      var itemsQty = data.items.length * LS;
      var elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 3000 + itemsQty * 3;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      // var tax_value = 1.19;
      const ctx = elem.getContext("2d");

      var items = data.items;
      items.forEach((item) => {
        item.quantity = parseInt(item.quantity);
        item.price = parseInt(item.price);
        item.discount = parseInt(item.discount);
      });
      var regimen = "NO RESPONSABLES DEL IVA";

      if (data.seller.TaxLevelCode) {
        switch (data.seller.TaxLevelCode) {
          case "0":
            regimen = "NO RESPONSABLES DEL IVA";
            break;
          case "1":
            regimen = "RESPONSABLES DEL IVA";
            break;
          default:
            regimen = "NO RESPONSABLES DEL IVA";
        }
      }

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(data.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(data.seller.PartyName, center, line);
      line += LS;
      // var typeID = null;
      //console.log("======>",data.company);
      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.code == data.company.typeid
        ).name +
          " : " +
          data.company.PartyIdentification,
        center,
        line
      );
      line += LS;
      ctx.fillText(regimen, center, line);
      line += LS;

      ctx.fillText(
        data.company.AddressLine +
          ", " +
          this.settings["municipalities"].find(
            (item) => item.id == data.company.city_code
          ).name,
        center,
        line
      );

      line += LS;
      ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
      line += LS;
      ctx.fillText("NO RETENEDORES DE IVA", center, line);

      switch (data.document.doc_type) {
        case "invoice":
          line += LS;
          ctx.fillText("FACTURA ELECTRONICA DE VENTA", center, line);
          ctx.font = "bold 40px Arial";
          line += LS;
          ctx.fillText("No. " + data.document.invoice, center, line);
          break;
        case "pos":
          line += LS;
          ctx.fillText("DOCUMENTO EQUIVALENTE", center, line);
          line += LS;
          ctx.fillText("A LA FACTURA DE VENTA", center, line);
          line += LS;
          ctx.fillText("SISTEMA POS", center, line);
          ctx.font = "bold 40px Arial";
          line += LS;
          ctx.fillText("No. " + data.document.invoice, center, line);
          break;
        case "NC":
          line += LS;
          ctx.fillText("NOTA CREDITO", center, line);
          ctx.font = "bold 40px Arial";
          line += LS;
          ctx.fillText("No. " + data.document.doc_number, center, line);
          break;
        default:
          line += LS;
          ctx.fillText("No. " + data.document.doc_number, center, line);
          break;
        // code block
      }

      line += LS;
      ctx.fillText(data.document.issuedate, center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      // if (data.payments[0].hora) {

      // } else {
      //   ctx.fillText(data.document.issueDate, center, line);
      // }

      line += LS;
      ctx.textAlign = "start";
      var PartyTypeId = "";
      if (data.buyer.PartyTypeId) {
        PartyTypeId = this.settings["type_document_identifications"].find(
          (item) => item.id == data.buyer.PartyTypeId
        ).name;
        ctx.fillText(PartyTypeId + " : " + data.buyer.PartyIdentification, left, line);
      }

      line += LS;
      ctx.font = "normal 35px Arial";
      ctx.fillText("Nombre / Razón Social: ", left, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText(data.buyer.PartyName, left, line);
      line += LS;
      ctx.fillText("Email     : " + data.buyer.PartyEmail, left, line);

      line += LS;
      line += LS;
      LS = 60;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Productos", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      total = 0;
      var tdiscount = 0;
      var idx = 1;
      ctx.textAlign = "start";
      ctx.fillText("Item", left, line);
      ctx.textAlign = "start";
      ctx.fillText("Detalle", left + 120, line);
      ctx.textAlign = "end";
      ctx.fillText("Cant.", right - 200, line);
      ctx.textAlign = "end";
      ctx.fillText("Valor", right, line);

      line += LS;
      const result = Object.groupBy(items, ({ item_code }) => item_code);

      Object.entries(result).forEach(([key, dta]) => {
        ctx.font = "bold 40px Arial";
        ctx.fillText(key, left, line);
        line += LS;
        ctx.font = "normal 35px Arial";
        dta.forEach((row) => {
          var discount = row.item_discount * row.item_quantity;
          //var siniva = row.item_price - row.item_tax;
          ctx.textAlign = "start";
          ctx.fillText(idx, left, line);
          ctx.textAlign = "start";
          ctx.fillText(row.item_concept, left + 50, line);
          ctx.textAlign = "end";
          ctx.fillText(row.item_quantity, right - 200, line);
          ctx.textAlign = "end";
          ctx.fillText(row.item_price.toLocaleString(), right, line);
          line += LS;
          ctx.font = " 35px Arial";
          ctx.textAlign = "start";
          ctx.fillText("Cod: " + row.item_code, left + 50, line);
          line += LS;
          idx += 1;

          // if (discount > 0) {
          //   ctx.fillText(
          //     " IVA: $" + row.item_tax + " / Descuento: $" + discount.toLocaleString(),
          //     left + 50,
          //     line
          //   );
          // } else {
          //   if (row.item_tax > 0) {
          //     ctx.fillText(
          //       "IVA " +
          //         Math.round((tax_value - 1) * 100) +
          //         "% :" +
          //         (row.item_tax * row.item_quantity).toLocaleString("es"),
          //       left + 50,
          //       line
          //     );
          //   }
          // }
          line += LS;
          ctx.font = "bold 40px Arial";
          total += parseFloat(row.item_price);
          tdiscount += discount;
        });
      });

      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      ctx.textAlign = "start";
      ctx.fillText("TOTAL", left, line);
      ctx.textAlign = "end";
      ctx.fillText(total.toLocaleString(), right, line);
      line += LS;

      if (tdiscount > 0) {
        ctx.textAlign = "start";
        ctx.fillText("Descuento aplicado", left, line);
        ctx.textAlign = "end";
        ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
        line += LS;
      }

      line += LS;
      ctx.textAlign = "center";
      ctx.fillText("Medio de pago", center, line);
      line += LS;
      //console.log(data.payments);
      data.payments.forEach((row) => {
        //console.log(row);
        ctx.textAlign = "start";
        ctx.fillText(row.payment_concept, left, line);
        if (row.payment_reference) {
          ctx.fillText(" " + row.payment_reference, left, line);
        }
        ctx.textAlign = "end";
        ctx.fillText(parseFloat(row.payment_value).toLocaleString(), right, line);
        line += LS;
      });
      line += LS;
      ctx.textAlign = "center";

      line += LS;

      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      ctx.font = " 35px Arial";

      ctx.textAlign = "center";

      ctx.fillText("Modo de Operacion  Software Propio", center, line);
      line += 35;

      ctx.fillText(data.seller.PartyName, center, line);
      line += 35;
      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.code == data.company.typeid
        ).name +
          " : " +
          data.company.PartyIdentification,
        center,
        line
      );
      line += 35;

      line += LS;

      // ctx.fillText("Productos", center, line);
      line += LS;
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
    },

    get_e_doc() {
      var doc = {
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        did: this.item.document.doc_number,
      };
      webserver("get_invoice_fe", doc, (data) => {
        console.log("get_e_doc", data);
        this.print_invoice(data);
        //data.doc.doc_type = "NC";
        //data.doc.doc_number = null;
        //this.put_e_doc(data);
      });
    },
    get_e_doc_credit_note() {
      var doc = {
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        did: this.item.document.doc_number,
      };
      webserver("get_invoice_fe", doc, (data) => {
        //console.log("get_e_doc",data);
        // this.item=data;
        this.e_credit_note(data);
        //data.doc.doc_type = "NC";
        //data.doc.doc_number = null;
        //this.put_e_doc(data);
      });
    },

    create_nc() {
      var dnc = { ...this.item };
      dnc.items = this.selected;
      var total = 0;
      dnc.items.forEach((itm) => {
        total += parseFloat(itm.item_quantity) * parseFloat(itm.item_price);
      });
      console.log("create_nc", dnc);

      dnc.payments = [
        {
          payment_code: "99",
          payment_concept: "NOTA CREDITO",
          payment_reference: "",
          payment_value: -total,
        },
      ];

      dnc.document.doc_value = total;
      var doc = {
        store: this.item.document.store,
        data: dnc,
      };

      webserver("post_note", doc, (data) => {
        this.nc_dialog = false;
        this.$emit("refresh", data);
        //this.item = data;
        // this.get_e_doc_credit_note(data);
        //this.loading_vendors = false;
      });
    },

    put_credit_note(data) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.$store.state.seller.store_business_id,
        data: data,
        store: this.item.document.store,
        issueDate: this.item.document.issuedate,
        did: this.item.document.doc_number,
      };
      this.loading_status = true;
      console.log("put_credit_note", qry);
      fe_ws("post_credit_note", qry, (data) => {
        this.loading_status = false;
        console.log(data);
        this.dian_dialog = true;
        this.dian_response = JSON.parse(data.data);
        this.loading_status = false;
      });
    },
    put_e_doc(e) {
      var doc = {
        store: this.item.document.store,
        data: e,
        action: "cancel",
      };
      webserver("put_e_doc", doc, (data) => {
        //this.e_credit_note(data);
        console.log(data);
        this.loading_vendors = false;
      });
    },
  },
  watch: {
    item: function (e) {
      console.log("item", e);
      this.print_invoice(e);
    },
  },
};
</script>
